import { template as template_6a8c28d553944256a1a240c8ae867dff } from "@ember/template-compiler";
const FKText = template_6a8c28d553944256a1a240c8ae867dff(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
