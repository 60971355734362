import { template as template_7049c0b0181c4cd8917a9e30ba04d8ca } from "@ember/template-compiler";
const FKLabel = template_7049c0b0181c4cd8917a9e30ba04d8ca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
